import { FixSelectPipe } from './../fix-select/fix-select.pipe';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {StudentRegistrationComponent} from '../../gestor-academico/program/student-registration/student-registration.component';
import {EventAttendeeComponent} from '../../gestor-academico/events/event-attendee/event-attendee.component';
import {Data, Router, ActivatedRoute, Event} from '@angular/router';
import {ApiService} from '../../core/api/api.service';
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Texts } from '../../gestor-academico/classes/texts';
import { FormUtils } from '../../gestor-academico/classes/form-utils';
import { FileUtils } from '../../gestor-academico/classes/file-utils';
import { HttpResponse } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'; // Importar DomSanitizer y SafeResourceUrl
import { map } from 'rxjs/operators';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export class DataModalForm {
  formControls: ModalFormField[];
  modalTitle: string;
  endpoint: string;
  id?: string|number;
  edit?: boolean;
  message?: string;
  isPreview?: boolean;
  documentId?: string|number;
  title_button?: string = null;
}
export class ModalFormField {
  /**
   * Nombre del campo a enviar al API
   *
   * @type {string}
   * @memberof ModalFormField
   */
  name: string;
  /**
   * Titulo del campo
   *
   * @type {string}
   * @memberof ModalFormField
   */
  title: string;
  /**
   * Valor por defecto
   *
   * @type {*}
   * @memberof ModalFormField
   */
  value: any;
  /**
   * Tipo de elemento de formulario ('text', 'email', 'number', 'date', 'time', 'select', 'ckeditor)
   *
   * @type {string}
   * @memberof ModalFormField
   */
  type: string;
  /**
   * Es requerido?
   *
   * @type {Boolean}
   * @memberof ModalFormField
   */
  required?: Boolean;
  /**
   * Es de solo lectura?
   *
   * @type {Boolean}
   * @memberof ModalFormField
   */
  readonly?: Boolean = false;;  
  /**
   * Mínimo
   *
   * @type {number}
   * @memberof ModalFormField
   */
  minValue?: number;
  /**
   * Máximo
   *
   * @type {number}
   * @memberof ModalFormField
   */
  maxValue?: number;
  /**
   * Tipo de autocomplete ('startswith','contains')
   *
   * @type {string}
   * @memberof ModalFormField
   */
  autoCompleteType?: string;
  /**
   * Datos extra necesario para el select
   *
   * @type {SelectData}
   * @memberof ModalFormField
   */
  selectData?: SelectData;
}
export class SelectData {
  /**
   * Nombre de la propiedad a mostrar en el select
   *
   * @type {string}
   * @memberof SelectData
   */
  propertyName: string;
  /**
   * Lista de elementos del select
   *
   * @type {any[]}
   * @memberof SelectData
   */
  itemList: any[];
  /**
   * Función a llamar tras seleccionar un elemento de la lista
   */
  customUrlFunction?: CustomUrlFunction;
}
export interface CustomUrlFunction {
  change: Function;
}

@Component({
  selector: 'ad-modal-form',
  templateUrl: './modal-form.component.html',
  styleUrls: ['./modal-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ModalFormComponent implements OnInit {
  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'bulletedList',
        'numberedList',
        '|',
        'undo',
        'redo'
      ]
    },
    language: 'es',
    licenseKey: 'GPL',
  };

  formGroup: UntypedFormGroup;
  modalTitle: string;
  errors = Texts.errors;
  // displayFn = FormUtils.displayFn;
  displayFn: any = {};
  filteredLists: any = {};
  selectedAutocomplete: any = {};
  documentContent: string;
  isPreview: boolean = false;
  pdfSrc: SafeResourceUrl;
  fileName: string | null = null;
  fileExtension: string | null = null;
  constructor(
    public dialogRef: MatDialogRef<ModalFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataModalForm,
    public dialog: MatDialog,
    private _fb: UntypedFormBuilder,
    private sanitizer: DomSanitizer, // Asegúrate de inyectar DomSanitizer
    private _apiService: ApiService,
    private fixSelectPipe: FixSelectPipe
  ) { }

  ngOnInit() {
    // console.log('this.data', this.data.formControls);
    if (this.data.isPreview) {
      this.isPreview = true;
      this.loadDocumentContent(this.data.documentId);
    } else {
      this._buildForm(this.data.formControls);
    }
    // console.log('data', this.data);
    // console.log('formGroup', this.formGroup);
    // console.log('formGroup.valid', this.formGroup.valid);
  }

  public onSubmit() {
    if (this.formGroup.valid) {

      if (this.data.id) {
        this._apiService.getResourceService(this.data.endpoint).update(
          FormUtils.debuildForm(this.formGroup.value), this.data.id
        ).subscribe(
          (res) =>  this.dialogRef.close(res)
        );
      }else {
        this._apiService.getResourceService(this.data.endpoint).add(
          FormUtils.debuildForm(this.formGroup.value)
        ).subscribe(
          (res) =>  this.dialogRef.close(res)
        );
      }

    }
  }

  public onCancel() {
    this.dialogRef.close();
  }

  private _buildForm(formList: ModalFormField[]) {
    const group = {};
    formList.forEach(item => {
      const validators = [];
      if (item.type === 'select' && !item.selectData) {
        throw new Error(`${item.name} debe contener la propiedad select de tipo SelectData`);
      }
      if (item.title && item.required) {
        validators.push(Validators.required);
      }
      if (item.type === 'email' && item.required) {
        validators.push(Validators.email);
      }
      if (item.title && ('minValue' in item)) {
        validators.push(Validators.min(item.minValue));
      }
      if (item.title &&  ('maxValue' in item)) {
        validators.push(Validators.max(item.maxValue));
      }
      if (item.type === 'select' || item.type === 'autocomplete') {
        if (item.value !== null) {
          item.value = this.fixSelectPipe.transform(item.value, item.selectData.itemList);
        }
      }
      if (item.type === 'autocomplete') {
        const temp = item.selectData.itemList.find(data => data.id === item.value);
        group[`selected${item.name}`] = temp || {};
        this.displayFn[item.name] = (val) => FormUtils.displayFn(val, item.selectData.propertyName);
      }
      if (item.type === 'ckeditor') {        
        // Asegurarnos de que el valor sea una cadena
        const initialValue = typeof item.value === 'string' ? item.value : '';
        group[item.name] = [initialValue, validators];
      } else {
        group[item.name] = [item.value, validators];
      }
    });
    this.formGroup = this._fb.group(group);
    formList.forEach(item => {
      if (item.type === 'autocomplete') {

        this.filteredLists[item.name] = [];
        const tempList = [].concat(item.selectData.itemList);
        if (item.autoCompleteType ){
          if (item.autoCompleteType === 'startswith') {
            this.filteredLists[item.name] = this.formGroup.get(`selected${item.name}`).valueChanges
            .pipe(
              map(listItem => listItem ? FormUtils.filterAutoComplete(listItem, tempList,  item.selectData.propertyName) : tempList.slice())
            )
          }
          if (item.autoCompleteType === 'contains') {
            this.filteredLists[item.name] = this.formGroup.get(`selected${item.name}`).valueChanges
            // tslint:disable-next-line:max-line-length
            .pipe(
              map(listItem => listItem ? FormUtils.filterAutoCompleteSearch(listItem, tempList,  item.selectData.propertyName) : tempList.slice())
            )
          }
        } else {
          this.filteredLists[item.name] = this.formGroup.get(`selected${item.name}`).valueChanges
          .pipe(
            map(listItem => listItem ? FormUtils.filterAutoComplete(listItem, tempList,  item.selectData.propertyName) : tempList.slice())
          )
        }

        // this.filteredLists[item.name] = this.formGroup.get(`selected${item.name}`).valueChanges

        // // tslint:disable-next-line:max-line-length
        // .map(listItem => listItem ? FormUtils.filterAutoComplete(listItem, tempList,  item.selectData.propertyName) : tempList.slice());
      }
    });
  }
  // Método para cargar los documentos dentro del iframe
  loadDocumentContent(documentId: string | number) {
    // console.log(`Loading document content for ID: ${documentId}`);
    this._apiService.getResourceService(this.data.endpoint)
      .getFile(`${documentId}/preview`)
      .subscribe((res: HttpResponse<Blob>) => {
        // console.log('File response received');
        const contentDisposition = res.headers.get('Content-Disposition') || '';
        // Extrae el nombre del archivo si existe
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/[^\/\\]+$/); // Extrae el último segmento (nombre del archivo)
          if (fileNameMatch) {
            this.fileName = fileNameMatch[0];
            this.fileExtension = this.fileName.split('.').pop() 
              ? this.fileName.split('.').pop()!.toLowerCase().trim()
              : '';
          }
        }
        // console.log(this.fileExtension);
        
        // Aquí está el cambio principal - usando res.body en lugar de res.blob()
        const blob = res.body;
        const url = URL.createObjectURL(blob);
        this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }, error => {
        console.error('Error al cargar el contenido del documento:', error);
      });
  }
  // Método para descargar los documentos dentro del modal
  downloadDocument() {
    this._apiService.getResourceService(this.data.endpoint)
      .getFile(`${this.data.documentId}/download`)
      .subscribe(res => {
        const filename = res.headers.get('Content-Disposition');
        FileUtils.getFile(res, filename);
      });
  }

  selectDone(event: MatAutocompleteSelectedEvent, field: string) {
    if (event.option.value && event.option.value.id) {
      this.formGroup.get(field).patchValue(event.option.value.id);
    }
    
    const selectedValue = event.option.value;
    // console.log(selectedValue);
    if (selectedValue) {
      // Para campos que necesitan el título y la URL
      if (selectedValue.title) {
        this.formGroup.get(field)?.patchValue(selectedValue.title);
        if (this.formGroup.get('url')) {
          this.formGroup.get('url')?.patchValue(selectedValue.url);
        }
      }
      // Para campos que necesitan el subtítulo
      if (selectedValue.subtitle) {
        this.formGroup.get('subtitle')?.patchValue(selectedValue.subtitle);
      }
      
      // Para campos que necesitan las fechas
      if (selectedValue.start_date) {
        this.formGroup.get('start_date')?.patchValue(selectedValue.start_date);
      }
      if (selectedValue.end_date) {
        this.formGroup.get('end_date')?.patchValue(selectedValue.end_date);
      }

      // Para campos que necesitan la descripción
      if (selectedValue.description) {
        this.formGroup.get('description')?.patchValue(selectedValue.description);
      }
    }
    // if (event.option.value && event.option.value.title) {
    //   this.formGroup.get(field).patchValue(event.option.value.title);
    //   this.formGroup.get('url').patchValue(event.option.value.url);
    // }
  }
  selectChange(event: MatSelectChange, field: string) {
    const pos = this.data.formControls.map(function(e) { return e.name; }).indexOf(field);
    if (this.data.formControls[pos].selectData.customUrlFunction){
      this.data.formControls[pos].selectData.customUrlFunction.change(event.value);
    }
  }
  autoCompleteText(event, field: string) {
    if (event.target.value === '') {
      this.formGroup.get(field).patchValue('');
    }
  }

  resizeIframe(iframe: HTMLIFrameElement) {
    const iframeDoc = iframe.contentDocument || (iframe.contentWindow ? iframe.contentWindow.document : null);
  
    if (iframeDoc) {
      const img = iframeDoc.querySelector('img') as HTMLImageElement;
  
      if (img) {
        img.onload = () => {
          iframe.style.height = img.naturalHeight + 'px'; // Ajusta la altura a la imagen
        };
      }
    }
  }
}
